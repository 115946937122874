import { ApiPath } from "paths";
import { Get, Post } from "services/api-inventory.service";

export const searchProduct = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_PRODUCT_ONLINE_ORDER}`, body);
  return res;
};

export const getProduct = async (productId) => {
  const res = await Get(`${ApiPath.PRODUCT_ID(productId)}`);
  return res;
};

export const placeProductOrder = async (body) => {
  const res = await Post(`${ApiPath.PLACE_ORDER_ONLINE_ORDER}`, body);
  return res;
};

export const validateProductOrder = async (body) => {
  const res = await Post(`${ApiPath.VALIDATE_ORDER_ONLINE_ORDER}`, body);
  return res;
}