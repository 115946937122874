export const locale = {
	somethingWentWrong: "Something went wrong.",
	pageNotFound: "404 Page not Found",
	looksLikeYouEncounteredSomething:
		"Looks like you encountered an error, we track these errors automatically. For the mean time, try refreshing.",
	ok: "Ok",
	getStarted: "Get Started",
	myApps: "My Apps",
	docs: "Docs",
	save: "Save",
	cancel: "Cancel",
	discard: "Discard",
	noResultsFound: "No Results Found",
	appDetails: "App Details",
	placeholder: "Placeholder",
	appCreated: "App Created",
	createNewApp: "Create New App",
	provideYourAppBasicDetails: "Provide your app's basic details",
	appName: "App Name",
	accountSettings: "Account Settings",
	logout: "Logout",
	integrations: "Integrations",
	pouchConnect: "PouchCONNECT",
	provideXCredentials: "Provide {0} Credentials",
	addFieldParameter: "Add Field/Parameter",
	integrate: "Integrate",
	descriptionPlaceholder:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
	delete: "Delete",
	view: "View",
	clientSecret: "Client Secret",
	clientID: "Client ID",
	nCopiedInClipboard: "{0} copied in clipboard.",
	authentication: "Authentication",
	copyAllAuthenticationAsJSONObj: "Copy your credentials as JSON Object.",
	areYouSureYouWantToDeleteThisApp:
		"Are you sure you want to delete this app?",
	askDeleteName: "Delete '{0}'?",
	edit: "Edit",
	credentials: "Credentials",
	areYouSureYouWantToDeleteThisWebhook:
		"Are you sure you want to delete this webhook?",
	accessToken: "Access Token",
	venue: "Venue",
	loginSessionEnded: "Login Session Ended",
	loginHasEnded: "Your login session has already ended. Please login again.",
	findUsOn: "Find us on",
	allRightsReserved: "All rights reserved.",
	copyrightYear: "Copyright © 2021",
	pouchnation: "PouchNATION",
	support: "Support",
	company: "Company",
	aboutUs: "About Us",
	termsOfUse: "Terms of Use",
	privacy: "Privacy",
	feedback: "Feedback",
	contactUs: "Contact Us",
	ourProducts: "Our Products",
	pouchVenue: "PouchVENUE",
	pouchEvent: "PouchEVENT",
	pouchPass: "PouchPASS",
	web: "Web",
	accounts: "Accounts",
	venueDashboard: "Venue Dashboard",
	eventDashboard: "Event Dashboard",
	resources: "Resources",
	roadMap: "Roadmap",
	systemStatus: "System Status",
	glossary: "Glossary",
	login: "Login",
	createAnAccount: "Create an Account",
	viewAndOrderDirectly: "View and order directly to your table.",
	cart: "Cart",
	poweredByPouchnation: "Powered by PouchNATION Inc.",
	sorryWeCantFind: 'Sorry, we can’t find "{0}"',
	tryDifferentKeyword:
		"Try a different keyword or you may ask the staff for some guidance.",
	search: "Search...",
	placeOrder: "Place Order",
	note: "Note",
	leaveANote: "Leave a note or request...",
	availableDiscountsAndVouchers:
		"Available discounts and vouchers would be applied by the staff upon payment.",
	backToMenu: "Back to Menu",
	yourCartIsEmpty: "Your cart is empty!",
	cartItems: "Cart Items",
	addItems: "Add Items",
	orderFor: "Order for",
	whereAreYouSeated: "Where are you seated?",
	enterTableNumber: "Enter table number",
	table: "Table",
	optional: "optional",
	total: "Total",
	updateCart: "Update Cart",
	leaveANoteOrRequest: "Leave a note or request (optional)",
	selectVariant: "Select Variant",
	selectOne: "Select One",
	remove: "Remove",
	addToCart: "Add to Cart",
	removeItem: "Remove Item?",
	orderHasBeenPlaced: "Your order has been placed!",
	orderAgain: "Order Again",
	orderNumber: "Order No.",
	tableNumber: "Table No.",
	numberOfItems: "No. of Items",
	pleaseScreenshot:
		"Please take a screenshot and present this screen to the staff to confirm your order.",
	itemSummary: "Item Summary",
	onlineMenu: "Online Menu",
	itemUnavailable: "This item is unavailable.",
	itemsInCartUpdated: "There are items in your cart that have been updated.",
};

export default Object.freeze(locale);
