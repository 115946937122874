import { Field } from "enums";
import lang from "translations";
const initialFormState = (initialState = {}) => {
	const { id, notes = null } = initialState;
	return {
		id: {
			name: "id",
			value: id,
			type: Field.LABEL,
		},
		notes: {
			name: "notes",
			value: notes,
			type: Field.INPUT,
			maxLength: 125,
			placeholder: lang.leaveANoteOrRequest,
		},
	};
};

export default initialFormState;
