import React from "react";
import { Drawer, Icon, Logo, Text, Title } from "components/commons";
import classNames from "classnames";
import styles from "./page-header.module.scss";
import lang from "translations";
import useDrawer from "hooks/useDrawer";
import { useContext } from "react";
import { LocationContext } from "contexts";

const PageHeader = ({ tableName = "", children, isSearchOpen = false }) => {
	const headerDrawer = useDrawer();
	const { locationName } = useContext(LocationContext);
	return (
		<>
			<div
				className={classNames(
					styles.container,
					"px-sm bg-washed h-15 min-h-15 w-full sticky top-0 z-20 flex items-center justify-between"
				)}
			>
				<div
					className={classNames("flex items-center gap-2 w-3/4", {
						hidden: isSearchOpen,
						visible: !isSearchOpen,
					})}
				>
					<Icon
						name="hamburger"
						onClick={() => {
							headerDrawer.show();
						}}
						fontSize="32px"
					/>

					<Title lg className="truncate">
						{locationName}
					</Title>
					{tableName ? (
						<Text
							color="text-gray"
							size="text-xs md:text-base"
							className="ml-xs truncate"
						>
							{tableName}
						</Text>
					) : null}
				</div>
				{children}
			</div>
			<Drawer
				title={
					<div className="w-3/4 mt-sm">
						<Logo />
					</div>
				}
				footer={<Text label>{lang.poweredByPouchnation}</Text>}
				footerStyle={{ borderTop: 0, marginBottom: "10px" }}
				content={<Title lg>{locationName}</Title>}
				{...headerDrawer}
			/>
		</>
	);
};

export default PageHeader;
