import { Container, PageHeader } from "components/commons";
import React, { useCallback, useMemo, useState } from "react";
import { searchProduct } from "apis/product.api";
import { useApi, useFilter, useLocalStorage, useMount } from "hooks";
import { mapObject } from "services";
import {
	productListFilterRequest,
	productListResponse,
} from "mappers/product.mapper";
import { productFilterState } from "./filters";
import ProductCardList from "./product-card-list";
import { SearchProduct } from "modules";
import { useContext } from "react";
import { LocationContext } from "contexts";

const ProductList = () => {
	const { locationId, tableId } = useContext(LocationContext);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [cart, setCart] = useLocalStorage("cart", []);

	const {
		request: searchProductRequest,
		loading: loadingProduct,
		mappedData,
		result,
	} = useApi({
		api: searchProduct,
		isArray: true,
		handleOwnError: true,
		mapper: productListResponse,
	});

	const { modifyFilters, filterState, requestState } = useFilter(
		productFilterState({ locationId, tableId })
	);

	useMount(() => {
		fetchProducts(requestState);
	});

	const fetchProducts = useCallback(
		async (requestState) => {
			await searchProductRequest(
				mapObject(requestState, productListFilterRequest)
			);
		},
		[searchProductRequest]
	);

	const applyFilterCb = useCallback(
		(searchKey) => {
			const { requestState } = modifyFilters({
				page: 1,
				searchKey,
			});
			fetchProducts(requestState);
		},
		[fetchProducts, modifyFilters]
	);

	const tableName = useMemo(() => {
		const { metadata } = result || {};
		const { location } = metadata || {};
		const { onlineOrderTables } = location || {};
		let tId = tableId && parseInt(tableId);
		const tableInfo =
			onlineOrderTables?.find(({ tableId }) => tableId === tId) || {};

		return tableInfo.customName || tableInfo.defaultName;
	}, [result, tableId]);

	const onSearch = (searchKey, isOpen) => {
		if ((searchKey === undefined || !searchKey) && isOpen) {
			setIsSearchOpen(true);
		}
	};

	return (
		<Container padding="pt-xxs md:pt-0">
			<PageHeader
				isSearchOpen={isSearchOpen}
				location={result?.metadata.location?.locationName ?? ""}
				tableName={tableName}
			>
				<SearchProduct
					applyFilter={applyFilterCb}
					onSearch={onSearch}
					setIsSearchOpen={setIsSearchOpen}
					isSearchOpen={isSearchOpen}
				/>
			</PageHeader>
			<ProductCardList
				cart={cart}
				setCart={setCart}
				products={mappedData}
				loading={loadingProduct}
				total={result?.metadata.total || 0}
				requestState={requestState}
				fetchList={fetchProducts}
				searchKey={filterState.searchKey}
				isSearchOpen={isSearchOpen}
				tableName={tableName}
			/>
		</Container>
	);
};

export default ProductList;
