import enumeration from "./enumeration";

const DateTime = {
	A: "MMMM D, YYYY", // January 29, 2021
	B: "hh:mm:ss A", // 08:00:00 AM
	C: "MMM D, YYYY", // Jan 29, 2021
	D: "h:mm A", // 8:00 AM
	E: "HH:mm:ss", // 08:00:00
	F: "MM/DD/YYYY HH:mm:ss", // 12/31/2021 08:00:00,
	G: "DD MMMM YYYY h:mm A",
	H: "MMMM D, YYYY h:mm A",
	I: "MMM D, YYYY h:mm A", // Jan 12, 2021 2:00 AM
};

export default enumeration(DateTime);
