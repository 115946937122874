import { ProductPage } from "pages";
import React, { useMemo, Fragment, useCallback } from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { Image, Skeleton, Text, Title } from "components/commons";
import { LayoutC } from "components/layouts";
import { MenuLogo } from "images";
import lang from "translations";
import { useApi, useMount } from "hooks";
import { getCurrency, getLocation } from "apis";
import { LocationContext } from "contexts";
import { clearCart } from "services/app.service";
import ErrorPage from "pages/page-not-found/error.page";

const MenuPage = () => {
	const locationId = window.location.href.split("/")[3];
	const tableId = window.location.href.split("/")[4];
	const { request: getLocationRequest, result, error } = useApi({
		api: getLocation,
	});
	
	const { request: getCurrencyRequest, loading = true } = useApi({
		api: getCurrency,
	});

	useMount(() => {
		if (locationId !== localStorage.getItem("locationId")) {
      clearCart();
    }
		fetchLocationAndCurrency({ locationId });
	});

	const fetchLocationAndCurrency = useCallback(
		async ({ locationId }) => {
			const res = await getLocationRequest({ locationId });
			const currency = await getCurrencyRequest({
				venueId: res?.data?.venueId,
			});
			localStorage.setItem("currencyCode", currency?.code);
			localStorage.setItem("currencySymbol", currency?.symbol);
			localStorage.setItem("venueId", res?.data?.venueId);
			localStorage.setItem("locationId", locationId);
			localStorage.setItem("tableId", tableId);
		},
		[getLocationRequest, getCurrencyRequest, tableId]
	);

	const { locationName } = result?.data || {};
	const renders = useMemo(() => {
		return {
			"/:locationId": {
				exact: false,
				component: ProductPage,
			},
		};
	}, []);

	const routeNames = useMemo(() => {
		return Object.keys(renders);
	}, [renders]);

	const routes = useMemo(() => {
		return routeNames.map((r, key) => {
			return <Route {...renders[r]} path={r} key={key} />;
		});
	}, [routeNames, renders]);

	const pageContent = useMemo(() => {
		return (
			<LocationContext.Provider
				value={{
					locationName: result?.data?.locationName,
					locationId: locationId,
					tableId: tableId,
				}}
			>
				<LayoutC
					leftChild={
						!loading ? (
							<Fragment>
								<Switch>{routes}</Switch>
							</Fragment>
						) : (
							<Skeleton className="p-md" />
						)
					}
					rightChild={
						<div className="p-md">
							<Image
								src={MenuLogo}
								className="m-auto pb-md"
								width="84.21px"
								loading={loading}
								loadingSize="small"
								shape="square"
							></Image>
							<Title xl loading={loading}>{`${
								locationName ?? ""
							} ${lang.onlineMenu}`}</Title>
							<Text color="text-gray">
								{lang.viewAndOrderDirectly}
							</Text>
						</div>
					}
				></LayoutC>
			</LocationContext.Provider>
		);
		// eslint-disable-next-line
	}, [routes, locationName, loading]);
	if (error) {
		return <ErrorPage />;
	}
	return <Switch>{pageContent}</Switch>;
};

export default MenuPage;
