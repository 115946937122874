export const productFilterState = ({ locationId, tableId }) => ({
	page: 1,
	pageSize: 10000,
	onlineOrderStatus: true,
	searchKey: "",
	categories: [],
	locations: locationId ? [{ value: parseInt(locationId) }] : [],
	tables: tableId ? [{ value: parseInt(tableId) }] : [],
	posStatus: null,
	sort: { key: "dateCreated", value: "desc" },
	isForMobileOrdering: true,
});
