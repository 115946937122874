import React from "react";
import { Image, Skeleton, Text, Title } from "components/commons";
import SkeletonImage from "antd/lib/skeleton/Image";

const ProductCard = ({
	name,
	description,
	price,
	imageLink = "",
	onClick,
	loading,
	quantity = 0,
}) => {
	return !loading ? (
		<div
			className="bg-white p-md flex gap-4 hover:bg-gray-lightest"
			onClick={onClick}
		>
			<div className="flex justify-center items-center self-start pt-xs gap-2 hover:opacity-90 md:relative">
				{quantity > 0 && (
					<div
						className="absolute z-1 flex items-center justify-center 
								   bg-black rounded-full w-12 h-12 
								   border-gray-lightest-500/50 border-4 opacity-90 
								   hover:opacity-75"
					>
						<Title color="text-white text-center">{quantity}</Title>
					</div>
				)}
				<div className="w-32">
					<Image
						src={imageLink}
						className="rounded bg-white object-cover"
						height={128}
						width={128}
					/>
				</div>
			</div>
			<div className="flex flex-col justify-between">
				<div>
					<Text fontWeight="font-semibold" size="text-md" breakAll>
						{name}
					</Text>
					<Text
						color="text-gray"
						breakAll
						className="line-clamp-3 text-ellipsis overflow-hidden"
					>
						{description}
					</Text>
				</div>
				<div>
					<Text fontWeight="font-semibold" size="text-md">
						{price}
					</Text>
				</div>
			</div>
		</div>
	) : (
		<div className="bg-white p-md flex gap-4 hover:bg-gray-lightest">
			<div>
				<SkeletonImage />
			</div>
			<div className="flex flex-col justify-between">
				<div>
					<Skeleton single />
				</div>
				<div>
					<Skeleton single />
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
