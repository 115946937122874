import { Button, Drawer, Icon, Text, Title } from "components/commons";
import React, { useMemo, useContext } from "react";
import lang from "translations";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { ProductType } from "enums";
import { LocationContext } from "contexts";
import { v4 as uuidv4 } from "uuid";
import styles from "./order-confirmation.module.scss";

const OrderConfirmation = ({
	tableName,
	tableNumber,
	cartQuantity,
	totalPrice,
	cartItems,
	loading,
	error,
	data = {},
	fetchList,
	setCart,
	...props
}) => {
	const { locationName: location } = useContext(LocationContext);
	const { orderNumber, dateCreated, note } = data;
	const orderConfirmationHeader = useMemo(() => {
		return (
			<div
				className="bg-white w-full items-center sticky 
            top-0 flex justify-between z-10 p-4 drop-shadow-md"
			>
				<Title lg className="mr-7 break-all">
					{location}
				</Title>
				<Button
					onClick={() => {
						setCart([]);
						fetchList();
					}}
					type="plain"
					className="border-none"
					customPadding="p-0"
				>
					<Text underline size="text-base" fontWeight="font-bold">
						{lang.backToMenu}
					</Text>
				</Button>
			</div>
		);
	}, [location, fetchList, setCart]);

	const orderDetails = useMemo(() => {
		return (
			<div className="bg-white p-4 rounded-md">
				<div className="items-center flex flex-col">
					<Text
						color="text-gray-lighter"
						fontWeight="font-bold"
						className="text-center"
					>
						{location}
					</Text>
					<Title xl>
						{lang.orderNumber} {orderNumber || ""}
					</Title>
					<Text color="text-gray-lighter">{dateCreated || ""}</Text>
				</div>

				<div className="border-t-2 border-b-2 border-gray-lightest my-2">
					<div className="flex items-center justify-between my-2">
						<Text>{lang.tableNumber}</Text>
						<Text className="ml-10 break-all">
							{tableNumber || ""}
						</Text>
					</div>
					<div className="flex items-center justify-between my-2">
						<Text>{lang.numberOfItems}</Text>
						<Text>{cartQuantity}</Text>
					</div>
					<div className="flex items-center justify-between my-2">
						<Title>{lang.total}</Title>
						<Title>
							{formatNumberToMoneyWithCurrencySymbol(totalPrice)}
						</Title>
					</div>
				</div>

				<Text color="text-gray-lighter" className="text-center">
					{lang.pleaseScreenshot}
				</Text>
			</div>
		);
	}, [
		cartQuantity,
		location,
		dateCreated,
		orderNumber,
		tableNumber,
		totalPrice,
	]);

	const itemSummary = useMemo(() => {
		return (
			<div className="bg-white rounded-md mt-sm">
				<Title className="px-md pt-md">{lang.itemSummary}</Title>
				<div className="my-2 px-md">
					{cartItems &&
						cartItems.map((item) => {
							const {
								quantity,
								name,
								type,
								variants,
								retailPrice,
								totalPrice,
							} = item;
							if (type === ProductType.Variant) {
								return variants.map((v) => {
									const {
										name: variantName,
										quantity,
										retailPrice,
									} = v;
									const totalPrice = quantity * retailPrice;
									return (
										<div
											className="flex justify-between my-4 "
											key={uuidv4()}
										>
											<div className="flex gap-4">
												<div>
													<Title>{quantity}</Title>
												</div>
												<div>
													<Title className="break-all">
														{name}
													</Title>
													<Text
														color="text-gray-lighter"
														breakAll
													>
														{variantName}
													</Text>
												</div>
											</div>
											<div className={styles.price}>
												<Title>
													{formatNumberToMoneyWithCurrencySymbol(
														totalPrice
													)}
												</Title>
												<Text color="text-gray-lighter">
													{quantity} x{" "}
													{formatNumberToMoneyWithCurrencySymbol(
														retailPrice
													)}
												</Text>
											</div>
										</div>
									);
								});
							}
							return (
								<div
									className="flex justify-between my-4"
									key={uuidv4()}
								>
									<div className="flex gap-4">
										<div>
											<Title>{quantity}</Title>
										</div>
										<Title>{name}</Title>
									</div>
									<div className={styles.price}>
										<Title>
											{formatNumberToMoneyWithCurrencySymbol(
												totalPrice
											)}
										</Title>
										<Text color="text-gray-lighter">
											{quantity} x{" "}
											{formatNumberToMoneyWithCurrencySymbol(
												retailPrice
											)}
										</Text>
									</div>
								</div>
							);
						})}
				</div>
				<div className="border-t-2 border-gray-lightest flex gap-2 justify-between px-md pb-md pt-md">
					<Title color="text-gray-lighter" fontWeight="font-normal">
						{lang.note}
					</Title>
					<Text breakAll className="text-right">
						{note ?? "-"}
					</Text>
				</div>
				<div className="border-t-2 border-gray-lightest flex items-center justify-between px-md pb-md pt-md">
					<Title>{lang.total}</Title>
					<Title>
						{formatNumberToMoneyWithCurrencySymbol(totalPrice)}
					</Title>
				</div>
			</div>
		);
	}, [cartItems, totalPrice, note]);

	return (
		<Drawer
			{...props}
			loading={loading}
			header={orderConfirmationHeader}
			placement="right"
			paddingless
			height="100%"
			width={window.innerWidth < 768 ? "100%" : "50%"}
			bodyStyle={{
				backgroundColor: "#E2E4E4",
				padding: 0,
			}}
			content={
				<div className="p-md">
					<div className="bg-none text-center my-6">
						<Icon name="check" fontSize="48px" />
						<Title>{lang.orderHasBeenPlaced}</Title>
					</div>
					{orderDetails}
					{itemSummary}
				</div>
			}
			footer={
				<Button
					className="p-md inset-x-0 bottom-0 
					w-full mt-3 mb-3"
					onClick={() => {
						setCart([]);
						fetchList();
					}}
				>
					<Text color="white" size="text-base" fontWeight="font-bold">
						{lang.orderAgain}
					</Text>
				</Button>
			}
			footerStyle={{
				position: "relative",
				boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.12)",
				borderTop: "none",
				paddingBottom: "10px",
				color: "#E2E4E4",
			}}
			customClose={() => {
				setCart([]);
				fetchList();
			}}
		/>
	);
};

export default OrderConfirmation;
