import {
	DiscountStatus,
	StaffRole,
	StaffStatus,
	VoucherStatus,
	ProductType,
	VoucherType,
	GuestStatus,
	TransactionStatus,
	TapStatus,
	Field,
	ProductStockLevel,
	ProductCreationType,
	ApprovalStatus,
} from "enums";
import lang from "translations";

export const prettifyProductStockLevel = (stockLevel) => {
	return (
		{
			[ProductStockLevel.InStock]: "In Stock",
			[ProductStockLevel.CheckStock]: "Check Stocks",
			[ProductStockLevel.ReorderStock]: "Reorder Stocks",
			[ProductStockLevel.NoStock]: "No Stocks",
		}[stockLevel] || ""
	);
};

export const prettifyStaffRole = (staffRole) => {
	return (
		{
			[StaffRole.SellOnPos]: "Sell on POS",
			[StaffRole.TopupCredits]: "Topup Credits and Return Credits",
			[StaffRole.RegisterGuests]: "Register Guests",
			[StaffRole.Manager]: "Manager",
		}[staffRole] || ""
	);
};

export const prettifyStaffStatus = (staffStatus) => {
	return (
		{
			[StaffStatus.Awaiting]: "Awaiting invite response",
			[StaffStatus.Inactive]: "Inactive",
		}[staffStatus] || ""
	);
};

export const prettifyDiscountStatus = (discountStatus) => {
	return (
		{
			[DiscountStatus.Active]: "Active",
			[DiscountStatus.Inactive]: "Inactive",
			[DiscountStatus.Expired]: "Expired",
		}[discountStatus] || ""
	);
};

export const prettifyVoucherStatus = (voucherStatus) => {
	return (
		{
			[VoucherStatus.Active]: "Active",
			[VoucherStatus.Inactive]: "Inactive",
			[VoucherStatus.Expired]: "Expired",
		}[voucherStatus] || ""
	);
};

export const prettifyProductType = (productType, count) => {
	const isPlural = count > 1;
	return (
		{
			[ProductType.Standard]: lang.standard,
			[ProductType.Variant]: isPlural ? lang.variants : lang.variant,
			[ProductType.Composite]: lang.composite,
			[ProductType.SupplyItem]: lang.supplyItem,
		}[productType] || ""
	);
};

export const prettifyProductCreationType = (productCreationType, count) => {
	return (
		{
			[ProductCreationType.Whole]: lang.whole,
			[ProductCreationType.ProductSet]: lang.productSet,
			[ProductCreationType.Assemble]: lang.assemble,
		}[productCreationType] || ""
	);
};

export const prettifyVoucherType = (voucherType) => {
	return (
		{
			[VoucherType.Issue]: "Issue",
			[VoucherType.Redeem]: "Redeem",
			[VoucherType.Remove]: "Remove",
			[VoucherType.Usage]: "Usage",
		}[voucherType] || ""
	);
};

export const prettifyGuestStatus = (guestStatus) => {
	return (
		{
			[GuestStatus.CHECKED_IN]: "Checked-In",
			[GuestStatus.CHECKED_OUT]: "Checked-Out",
		}[guestStatus] || ""
	);
};

export const prettifyTransactionStatus = (transactionStatus) => {
	return (
		{
			[TransactionStatus.SUCCESS]: "Success",
			[TransactionStatus.VOID]: "Voided",
		}[transactionStatus] || ""
	);
};

export const prettifyTapStatus = (tapStatus) => {
	return (
		{
			[TapStatus.Success]: "Success",
			[TapStatus.Failed]: "Failed",
		}[tapStatus] || ""
	);
};

export const prettifyFieldType = (type) => {
	return (
		{
			[Field.INPUT]: lang.textbox,
			[Field.DROPDOWN]: lang.dropdown,
			[Field.CHECKBOX]: lang.checkbox,
			[Field.RADIO]: lang.radioButton,
			[Field.COUNTRY]: lang.country,
			[Field.NATIONALITY]: lang.nationality,
		}[type] || lang.textbox
	);
};

export const prettifyApprovalStatus = (approvalStatus) => {
	return (
		{
			[ApprovalStatus.Enabled]: "Enabled",
			[ApprovalStatus.Pending]: "Pending",
		}[approvalStatus] || ""
	);
};
