import { useCallback, useState, useContext } from "react";
import { AppContext } from "contexts";
import { mapObject, mapObjects } from "services";

const useApi = ({
	api,
	params = {},
	handleOwnError = false,
	mapper,
	isArray = false,
	paramsMapper,
	returnMappedData = false,
}) => {
	const [loading, setLoading] = useState(undefined);
	const [error, setError] = useState(false);
	const [result, setResult] = useState(undefined);
	const [mappedData, setMappedData] = useState(isArray ? [] : {});
	const { logOutSessionModal, globalErrorModal } = useContext(
		AppContext || {}
	);
	const [submittedParams, setSubmittedParams] = useState({});

	const mapData = useCallback(
		(res, params) => {
			let obj = null;
			if (isArray) {
				obj = mapObjects([...res.data], mapper, { ...params });
			} else {
				obj = mapObject({ ...res.data }, mapper, { ...params });
			}
			setMappedData(obj);
			return obj;
		},
		[mapper, isArray]
	);

	const request = useCallback(
		async (p, options = {}) => {
			const { noLoading = false } = options;
			if (!noLoading) {
				setLoading(true);
			}

			setError(false);
			try {
				setSubmittedParams({ ...params, ...p });
				const parameter = paramsMapper
					? mapObject({ ...params, ...p }, paramsMapper)
					: { ...params, ...p };
				const res = await api(parameter);
				setResult(res);
				setLoading(false);
				if (res) {
					const mappedDataResponse = mapData(res, parameter);
					if (returnMappedData) {
						return mappedDataResponse;
					}
				}
				return res;
			} catch (err) {
				console.error("API ERROR", err);
				const metadata = err?.metadata;
				const code = metadata?.code;
				err.code = code;

				setError(true);
				setLoading(false);

				const getErrorResponse = (type, defaultError) => {
					if (typeof handleOwnError === "boolean" && handleOwnError) {
						throw err;
					}
					if (
						handleOwnError[type] &&
						typeof handleOwnError[type] === "function"
					) {
						return handleOwnError[type]();
					}
					defaultError();
				};

				const showErrorAppState = () => {
					globalErrorModal.show();
				};

				err.handleError = () => {
					showErrorAppState();
				};

				const obj = {
					network: () => {
						getErrorResponse("network", () => showErrorAppState());
						throw err;
					},
					unauthorized: () => {
						logOutSessionModal.show();
						throw err;
					},
					server: () => {
						getErrorResponse("server", () => showErrorAppState());
						throw err;
					},
					badrequest: () => {
						if (handleOwnError?.badrequest) {
							const handler = handleOwnError.badrequest;
							if (
								code &&
								typeof handler === "object" &&
								handler.hasOwnProperty(code)
							) {
								handler[code]();
							}
						} else {
							getErrorResponse("badrequest", () =>
								showErrorAppState()
							);
						}
						throw err;
					},
				};
				if (obj.hasOwnProperty(err.error)) {
					obj[err.error]();
				} else {
					showErrorAppState();
					throw err;
				}
			}
		},
		[
			params,
			api,
			handleOwnError,
			paramsMapper,
			logOutSessionModal,
			globalErrorModal,
			mapData,
			returnMappedData,
		]
	);

	return { request, loading, result, error, mappedData, submittedParams };
};

export default useApi;
