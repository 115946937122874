import React, { useCallback } from "react";
import { Radio as AntRadio } from "antd";
import { Radio, Title } from "..";
import styles from "./radio.module.scss";
import classnames from "classnames";

const RadioGroup = ({ options = [], value, title, onChange, className }) => {
	const onChangeCb = useCallback(
		(e) => {
			if (onChange) {
				onChange(e.target.value);
			}
			return e.target.value;
		},
		[onChange]
	);

	return (
		<div>
			<Title className="mb-md" md>
				{title}
			</Title>
			<AntRadio.Group onChange={onChangeCb} value={value}>
				{options.map((o, i) => {
					return (
						<Radio
							key={i}
							className={classnames(
								"mb-md",
								styles.container,
								className
							)}
							{...o}
						/>
					);
				})}
			</AntRadio.Group>
		</div>
	);
};

export default RadioGroup;
