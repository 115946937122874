import { ApiPath } from "paths";
import { Get } from "services/api-inventory.service";

export const getVenues = async () => {
	const res = await Get(`${ApiPath.GET_VENUES}`, {});
	return res.data;
};

export const getCurrency = async ({ venueId }) => {
	const res = await Get(`${ApiPath.GET_CURRENCY(venueId)}`);
	return res.data;
};
