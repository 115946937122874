import React from "react";
import { PageHeader, Container } from "components/commons";
import classnames from "classnames";
import styles from "./layout-b.module.scss";

const LayoutA = ({ children, padding, paddingless, logoRedirectUrl }) => {
	return (
		<div
			className={classnames(
				"flex flex-col h-full bg-gray-lightest w-1/2"
			)}
		>
			<PageHeader logoRedirectUrl={logoRedirectUrl} />
			<Container
				padding={padding}
				mb={"mb-0"}
				paddingless={paddingless}
				className={classnames("flex-grow", styles.layoutAContainer)}
			>
				{children}
			</Container>
		</div>
	);
};

export default LayoutA;
