import React from "react";
import classnames from "classnames";
import { Icon } from "components/commons";
import { message as antMessage } from "antd";
import styles from "./toast.module.scss";
const Toast = ({ className, content, success, children, icon, error, duration = 3, maxCount }) => {
  const render = (
    <div
      className={classnames(styles.container, className, "p-md rounded font-medium", {
        "bg-red text-white": error,
        "bg-green text-white": success,
      })}
    >
      {icon && <Icon className={classnames("icon")} name={icon} />} {content || children}
    </div>
  );
  return {
    open: () => {
      if (maxCount && maxCount > 0) {
        antMessage.config({ maxCount });
      }
      antMessage.info({ content: render, duration });
    },
    close: () => {
      antMessage.destroy()
    }
  };
};

export default Toast;
