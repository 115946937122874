import { Field } from "enums";
import Validation from "services/validation.service";
const initialFormState = (initialState = {}) => {
	const { tableName, notes = null } = initialState;
	return {
		tableName: {
			name: "tableName",
			value: tableName,
			type: Field.INPUT,
			maxLength: 75,
			validations: [Validation.required()],
		},
		notes: {
			name: "notes",
			value: notes,
			type: Field.INPUT,
			maxLength: 255,
		},
	};
};

export default initialFormState;
