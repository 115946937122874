import { profilePaths } from "./profile.path";
import { authPaths } from "./auth.path";
import venuePaths from "./inventory/venue.path";
import productPaths from "./inventory/product.path";
import locationPaths from "./inventory/location.path";
import currencyPaths from "./inventory/currency.path";

const preparePaths = ({ prefix, paths }) => {
	let newPaths = {};

	for (const [k, path] of Object.entries(paths)) {
		if (typeof path === "function") {
			newPaths[k] = (id) => `${prefix}/${path(id)}`.replace(/\/+/g, "/");
		} else if (typeof path === "string") {
			if (path.length > 0) {
				newPaths[k] = `${prefix}/${path}`.replace(/\/+/g, "/");
			} else {
				newPaths[k] = `${prefix}`.replace(/\/+/g, "/");
			}
		} else {
			newPaths[k] = path;
		}
	}
	return newPaths;
};

const ApiPath = {
	...preparePaths({ prefix: "auth", paths: authPaths }),
	...preparePaths({
		prefix: "profile",
		paths: profilePaths,
	}),
	...preparePaths({
		prefix: "storage",
		paths: {
			STORAGE_UPLOAD: "upload",
		},
	}),
	...preparePaths({
		prefix: "inventory/online-order/venue",
		paths: venuePaths,
	}),
	...preparePaths({
		prefix: "inventory/online-order/currency",
		paths: currencyPaths,
	}),
	...preparePaths({ prefix: "inventory/online-order", paths: productPaths }),
	...preparePaths({
		prefix: "inventory/online-order/location",
		paths: locationPaths,
	}),
	CHECK_EMAIL: "inventory/profile/staff/check",
	...preparePaths({
		prefix: "applications",
		paths: {
			LOGO_UPLOAD: (id) => `${id}/logo`,
		},
	}),
};

export default ApiPath;
