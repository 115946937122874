import locale from "translations";
import React from "react";
import { Container, Title } from "components/commons";

const ErrorPage = () => {
  return (
    <Container className="justify-center flex justify-center">
      <Title xl>{locale.pageNotFound}</Title>
    </Container>
  );
};

export default ErrorPage;
