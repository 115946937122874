const venuePaths = {
	GET_VENUES: "",
	GET_VENUE: (venueId) => venueId,
	VENUE_CREDIT_SETTINGS: (id) => `${id}/credit-settings`,
	VENUE_WRISTBAND: (id) => `${id}/keep-wristband`,
	GET_RECEIPT_SETTINGS: (id) => `${id}/receipt-settings`,
	SUPPLY_ITEM_VENUE_ID: (venueId) => `${venueId}/supply-item`,
	GET_CURRENCY: (venueId) => venueId,
};

export default venuePaths;
