import classNames from "classnames";
import { Icon, Input } from "components/commons";
import React, { useState } from "react";
import { useCallback, useRef } from "react";
import lang from "translations";

const SearchProduct = ({
	applyFilter,
	onSearch,
	isSearchOpen,
	setIsSearchOpen,
}) => {
	const [searchKey, setSearchKey] = useState();
	const searchRef = useRef();

	const onApplyCb = useCallback(
		(searchKeyOverride) => {
			if (searchKeyOverride === undefined || searchKeyOverride === null) {
				applyFilter(searchKey);
			} else {
				applyFilter(searchKeyOverride);
			}
		},
		[searchKey, applyFilter]
	);

	const onProductSearch = (searchKey, isOpen) => {
		onSearch(searchKey, isOpen);
	};

	return (
		<div
			className={classNames("flex items-center justify-end", {
				"w-full": isSearchOpen,
				"w-1/4": !isSearchOpen,
			})}
		>
			<Input
				readOnly={!isSearchOpen}
				className={!isSearchOpen ? "cursor-default" : ""}
				ref={searchRef}
				iconPrefix={
					<Icon
						name="search"
						fontSize="30px"
						onClick={() => {
							searchRef.current.focus();
							if (searchKey && searchKey !== "") {
								onProductSearch(searchKey, true);
							}
							setIsSearchOpen(true);
						}}
					/>
				}
				paddingless
				clearable
				placeholder={isSearchOpen ? lang.search : ""}
				onClick={() => {
					setIsSearchOpen(true);
					onProductSearch(searchKey, true);
				}}
				value={searchKey}
				onChange={(name, { value }) => {
					setSearchKey(value);
					onApplyCb(value);
				}}
				onClear={() => {
					setSearchKey("");
					onApplyCb("");
				}}
				onEnter={() => {
					onApplyCb(searchKey);
				}}
				collapsible
				onBlur={() => {
					setSearchKey(searchKey);
				}}
				closeInput={isSearchOpen}
			/>
			{isSearchOpen && (
				<Icon
					name="remove"
					onClick={() => {
						setIsSearchOpen(false);
						applyFilter(null);
						setSearchKey(null);
						onProductSearch(null, false);
					}}
				/>
			)}
		</div>
	);
};

export default SearchProduct;
