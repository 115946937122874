import React from "react";
import { Modal as AntModal } from "antd";
import classnames from "classnames";
import styles from "./modal.module.scss";
// import FullModal from "./full-modal";
import { Text, Icon } from "components/commons";

const Modal = ({
	active,
	children,
	title = "",
	actionContent,
	close,
	noHeader,
	hidden,
	width,
	noCloseButton = false,
	paddingless = false,
	closable = false,
	fullModal = false,
	...props
}) => {
	return (
		<AntModal
			className={classnames(
				{
					"opacity-0": hidden,
					[`${styles.fullModal}`]: fullModal,
				},
				styles.container
			)}
			width={width}
			title={
				noHeader ? null : (
					<div className="p-md mb-md flex items-center justify-between border-b border-solid border-border1">
						<Text modalTitle>{title}</Text>
						{!noCloseButton && (
							<Icon
								className="outline-none text-xl text-gray hover:text-gray cursor-pointer"
								name="remove"
								onClick={() => {
									close();
								}}
							/>
						)}
					</div>
				)
			}
			visible={active}
			footer={null}
			bodyStyle={{ padding: 0 }}
			maskClosable={closable}
			closable={false}
			onCancel={closable ? () => close() : null}
			{...props}
		>
			<div
				className={classnames({
					"pb-md": actionContent,
					"px-md": !paddingless,
					"bg-white": fullModal,
				})}
			>
				{children}
			</div>
			{actionContent}
		</AntModal>
	);
};

export default Modal;
