import React from "react";
import { ProductList } from "modules";
import { Route, Switch } from "react-router";
import { Path } from "paths";

const ProductPage = () => {
	return (
		<Switch>
			<Route path={Path.SLASH} component={ProductList} />
		</Switch>
	);
};

export default ProductPage;
