import React from "react";
import classnames from "classnames";
import styles from "./image.module.scss";
// import {
// 	LazyLoadImage,
// 	trackWindowScroll,
// } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
import { v4 as uuidv4 } from "uuid";
const Image = ({
	className,
	src,
	alt,
	onClick,
	// scrollPosition,
	height,
	width,
}) => {
	return (
		<img
			key={uuidv4()}
			className={classnames(className, styles.image)}
			onClick={onClick}
			src={src ?? "/static/media/default-image.451b1096.svg"}
			alt={alt}
			style={{
				width: width,
				height: height,
			}}
			// height={height}
			// width={width}
			// effect="blur"
			// onError={() => {
			// 	setImageSrc("/static/media/default-image.451b1096.svg");
			// }}
			// scrollPosition={scrollPosition}
		/>
	);
};

export default Image;
