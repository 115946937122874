import { Button, Modal, Text, Title } from "components/commons";
import { ProductType } from "enums";
import { useCallback } from "react";
import lang from "translations";

const CartRemoveItemModal = ({
	product,
	cart,
	setCart,
	showCart,
	...props
}) => {
	const removeItem = useCallback(
		async ({ product }) => {
			let cartCopy = cart;
			let existingItem = cartCopy.find((cart) => cart.id === product.id);
			const { id, type, variants, productSkuId } = product;
			if (type === ProductType.Variant) {
				let variantToRemove = variants.find(
					(variant) => variant.productSkuId === productSkuId
				);
				if (variantToRemove) {
					variants.splice(variantToRemove, 1);
					existingItem.quantity = variants.reduce((total, item) => {
						return total + item?.quantity;
					}, 0);
				}
				if (variants.length < 1) {
					cartCopy = cartCopy.filter((item) => item.id !== id);
				}
			} else {
				cartCopy = cartCopy.filter((item) => item.id !== id);
			}
			setCart(cartCopy);
			showCart();
		},
		[cart, setCart, showCart]
	);

	return (
		<Modal
			noHeader
			width={280}
			noCloseButton
			className="mb-lg"
			actionContent={
				<div className="pr-2 pb-3 flex justify-end">
					<Button
						type="plain"
						className="hover:text-black border-none"
						onClick={() => props.close()}
					>
						<Text
							size="text-base"
							color="text-gray"
							fontWeight="font-bold"
						>
							{lang.cancel}
						</Text>
					</Button>
					<Button
						type="plain"
						className="hover:text-black border-none"
						onClick={() => {
							removeItem({ product: product });
							props.close();
						}}
					>
						<Text
							color="text-red-light"
							size="text-base"
							fontWeight="font-bold"
						>
							{lang.remove}
						</Text>
					</Button>
				</div>
			}
			{...props}
		>
			<Title className="text-left pt-5 pl-2.5">{lang.removeItem}</Title>
		</Modal>
	);
};

export default CartRemoveItemModal;
