import { Button } from "components/commons";
import { LocationContext } from "contexts";
import useDrawer from "hooks/useDrawer";
import { ReviewCart } from "modules";
import React, { useContext } from "react";

const CartButton = ({
	cart = [],
	setCart,
	fetchProducts,
	requestState,
	tableName,
	cartInfo,
}) => {
	const { locationName } = useContext(LocationContext);
	const reviewCartDrawer = useDrawer();

	return (
		<>
			<div
				className="z-10 shadow-2xl p-md bg-white fixed inset-x-0 bottom-0
       w-full md:w-1/2"
			>
				<Button
					className="w-full"
					onClick={() => {
						reviewCartDrawer.show({
							location: locationName,
							tableName: tableName,
							fetchList: fetchProducts,
							requestState: requestState,
							cart: cart,
							setCart: setCart,
						});
					}}
				>
					{cartInfo}
				</Button>
			</div>
			<ReviewCart {...reviewCartDrawer} />
		</>
	);
};

export default CartButton;
