import React from "react";
import { Button, Container, Line } from "components/commons";
import lang from "translations";
import { StyleType } from "enums";
import classnames from "classnames";

const ActionButton = ({
  primary = {},
  secondary = {},
  danger = null,
  loading,
  deleting,
  className,
  showLine,
}) => {
  const {
    text: primaryText = lang.save,
    onClick: primaryOnClick,
    type: primaryType,
    disabled: primaryDisabled = false,
    className: primaryClassName,
  } = primary || {};
  const {
    text: secondaryText = lang.discard,
    className: secondaryClassName,
    onClick: secondaryOnClick,
  } = secondary || {};
  const { text: dangeryText = lang.delete, onClick: dangerOnClick } =
    danger || {};
  return (
    <>
      {showLine && <Line />}
      <Container
        mb={0}
        paddingless={true}
        parent
        className={classnames("flex justify-between", className)}
      >
        <div className="flex align-center">
          {danger && (
            <Button
              type={StyleType.Danger}
              loading={deleting}
              onClick={() => {
                if (!loading && !deleting) {
                  dangerOnClick();
                }
              }}
            >
              {dangeryText}
            </Button>
          )}
        </div>

        <div className="flex align-center">
          {secondary && (
            <Button
              // className={`${noBorder ? "border-none" : ""} ${textColor}`}
              className={secondaryClassName}
              type={StyleType.Secondary}
              disabled={loading}
              onClick={() => {
                if (!loading) {
                  secondaryOnClick();
                }
              }}
            >
              {secondaryText}
            </Button>
          )}
          {primary && (
            <Button
              className={`ml-sm ${primaryClassName}`}
              disabled={primaryDisabled}
              loading={loading}
              onClick={() => {
                if (!loading && !deleting) {
                  primaryOnClick();
                }
              }}
              type={primaryType}
            >
              {primaryText}
            </Button>
          )}
        </div>
      </Container>
    </>
  );
};

export default ActionButton;
