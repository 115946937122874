import lang from "translations";
import * as moment from "moment";

export const setPageTitle = (key) => {
	document.title = `${key ? `${lang[key]} - ` : ""}${lang.pouchConnect}`;
};

export const setGlobalDateTimeFilter = (dateRange) => {
	localStorage.setItem(
		"globalDateTimeFilter",
		[
			moment(dateRange[0]).format("MMDDYYYYHHmm"),
			moment(dateRange[1]).format("MMDDYYYYHHmm"),
		].join(",")
	);
};

export const getGlobalDateTimeFilter = () => {
	const dateTime = localStorage.getItem("globalDateTimeFilter");

	if (dateTime) {
		const d = dateTime.split(",");
		return [moment(d[0], "MMDDYYYYHHmm"), moment(d[1], "MMDDYYYYHHmm")];
	}
	return [moment().startOf("day"), moment().endOf("day")];
};

const timerIds = [];

export const debounced = (delay, fn) => {
	let timerId;
	return function (...args) {
		if (timerId) {
			clearTimeout(timerId);
		}

		timerId = setTimeout(() => {
			// trigger function only on last hit
			if (timerIds[timerIds.length - 1] === timerId) {
				fn(...args);
			}
			timerId = null;
		}, delay);
		timerIds.push(timerId);
	};
};

export const getVenueId = () => {
	return parseInt(localStorage.getItem("venueId"));
};

export const getLocationId = () => {
	const href = window.location.href.split("/");
	var locationId = localStorage.getItem("locationId");
	if (href.length >= 3) {
		locationId = href[3];
	}
	return parseInt(locationId);
};

export const getTableId = () => {
	return parseInt(localStorage.getItem("tableId"));
};

export const getCart = () => {
	return JSON.parse(localStorage.getItem("cart"));
};

export const clearCart = () => {
	localStorage.setItem("cart", JSON.stringify([]));
};
