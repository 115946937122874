import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContext } from "contexts";
import { useState } from "react";
import { Path } from "paths";
import { useModal } from "hooks";
import { LogoutSessionModal } from "components/modals";
import { OctopusHiding, OctopusRain } from "images";
import { Image, Loader, Text, Title } from "components/commons";
import { LayoutA } from "components/layouts";
import locale from "translations";
import { MenuPage } from "pages";
import ErrorPage from "pages/page-not-found/error.page";

function App() {
	const [appState, setAppState] = useState({ error: false });
	const logOutSessionModal = useModal();
	const globalErrorModal = useModal();
	const [globalLoading, setGlobalLoading] = useState(false);
	const [error, setError] = useState(false);

	return (
		<AppContext.Provider
			value={{
				appState,
				setAppState,
				logOutSessionModal,
				globalErrorModal,
				pageErrorImage: OctopusRain,
				setGlobalLoading,
				setGlobalError: setError,
			}}
		>
			<div className="App">
				{error ? (
					<LayoutA hasUserNav={false}>
						<div className="text-center">
							<Image className="m-auto" src={OctopusHiding} />
							<Title className="mt-md" xl color="text-blue">
								{locale.somethingWentWrong}
							</Title>
							<Text>
								{" "}
								{locale.looksLikeYouEncounteredSomething}
							</Text>
						</div>
					</LayoutA>
				) : (
					<Router>
						<Switch>
							<Route path={Path.SLASH + ":locationId"} component={MenuPage} />
							<Route path="*" component={ErrorPage} />
						</Switch>
					</Router>
				)}
			</div>
			<LogoutSessionModal {...logOutSessionModal} />
			<div className="hidden">
				<Image src={OctopusRain} />
			</div>
			<Loader loading={globalLoading} />
		</AppContext.Provider>
	);
}

export default App;
