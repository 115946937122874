import React from "react";
import { Radio as AntRadio } from "antd";
import classnames from "classnames";
import { Text } from "..";
import styles from "./radio.module.scss";

const Radio = ({
	customText,
	text,
	disabled,
	value,
	className,
	onClick,
	fontWeight,
	...props
}) => {
	return (
		<div className="flex items-center justify-between">
			<AntRadio
				className={classnames(
					"flex items-center",
					styles.container,
					className
				)}
				value={value}
				disabled={disabled}
				onClick={onClick}
				{...props}
			>
				{text && (
					<Text className="text-sm mt-sm" fontWeight={fontWeight}>
						{text}
					</Text>
				)}
			</AntRadio>
			{customText}
		</div>
	);
};

export default Radio;
