import { Drawer as AntDrawer } from "antd";
import React from "react";
import { Icon } from "..";
import { Skeleton } from "..";

const Drawer = ({
	placement = "left",
	title,
	header,
	content,
	footer,
	icon,
	customElement,
	width,
	closable = false,
	paddingless,
	visible,
	customClose,
	close,
	loading = false,
	footerStyle,
	...props
}) => {
	return (
		<div>
			{icon && <Icon name={icon} fontSize="32px" />}
			{customElement && <div>{customElement}</div>}
			<AntDrawer
				closable={closable}
				width={width}
				title={title}
				placement={placement}
				footer={footer}
				footerStyle={footerStyle}
				headerStyle={{ borderBottom: 0 }}
				visible={visible}
				key={placement}
				bodyStyle={{
					padding: paddingless && 0,
				}}
				{...props}
				onClose={() => {
					if (customClose) {
						customClose();
					} else {
						close();
					}
				}}
			>
				{header}
				{loading ? <Skeleton className="p-md" /> : content}
			</AntDrawer>
		</div>
	);
};

export default Drawer;
